import React, { useState } from 'react';
import './App.css';

function App() {
  const [initialSpeed, setInitialSpeed] = useState('');
  const [finalSpeed, setFinalSpeed] = useState('');
  const [duration, setDuration] = useState('');
  const [mass, setMass] = useState('');
  const [headwind, setHeadwind] = useState('');
  const [rollingResistance, setRollingResistance] = useState('40');
  const [drivetrainLoss, setDrivetrainLoss] = useState('2');
  const [result, setResult] = useState(null);

  const calculatePower = () => {
    // Parse input values
    const v_i = parseFloat(initialSpeed) / 3.6;
    const v_f = parseFloat(finalSpeed) / 3.6;
    const t = parseFloat(duration);
    const m = parseFloat(mass);
    const v_w = parseFloat(headwind) / 3.6;
    const P_rr = parseFloat(rollingResistance);
    const drivetrainLossPercentage = parseFloat(drivetrainLoss) / 100;

    // Constants
    const rho = 1.225;
    const Cd = 0.88;
    const A = 0.5;

    // Adjust speeds for headwind
    const v_i_eff = v_i + v_w;
    const v_f_eff = v_f + v_w;

    // Calculate kinetic energy change
    const W_ke = 0.5 * m * (v_f ** 2 - v_i ** 2);

    // Calculate average effective velocity
    const v_avg_eff = (v_i_eff + v_f_eff) / 2;

    // Calculate average drag force
    const F_d = 0.5 * rho * Cd * A * v_avg_eff ** 2;

    // Calculate work done against aerodynamic drag
    const d = ((v_i + v_f) / 2) * t;
    const W_d = F_d * d;

    // Calculate work done against rolling resistance
    const W_rr = P_rr * t;

    // Calculate total work done
    const W_total = W_ke + W_d + W_rr;

    // Calculate power output before drivetrain loss
    const P_before = W_total / t;

    // Account for drivetrain loss
    const P = P_before / (1 - drivetrainLossPercentage);

    setResult(P.toFixed(2));
  };

  return (
    <div className="container">
      <h1>Bike Sprint Power Calculator</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Initial Speed (km/h):
          <input type="number" value={initialSpeed} onChange={(e) => setInitialSpeed(e.target.value)} required />
        </label>
        <label>
          Final Speed (km/h):
          <input type="number" value={finalSpeed} onChange={(e) => setFinalSpeed(e.target.value)} required />
        </label>
        <label>
          Duration (seconds):
          <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} required />
        </label>
        <label>
          Mass (kg):
          <input type="number" value={mass} onChange={(e) => setMass(e.target.value)} required />
        </label>
        <label>
          Headwind Speed (km/h):
          <input type="number" value={headwind} onChange={(e) => setHeadwind(e.target.value)} required />
        </label>
        <label>
          Rolling Resistance (W):
          <input type="number" value={rollingResistance} onChange={(e) => setRollingResistance(e.target.value)} required />
        </label>
        <label>
          Drivetrain Loss (%):
          <input type="number" value={drivetrainLoss} onChange={(e) => setDrivetrainLoss(e.target.value)} required />
        </label>
        <button type="button" onClick={calculatePower}>Calculate Power</button>
      </form>
      {result !== null && (
        <div id="result">
          <h2>Power Output: {result} Watts</h2>
        </div>
      )}
    </div>
  );
}

export default App;
